import React from "react";
import "./PPTSlideNoteTextEditor.css";
import CircularProgress from "@mui/material/CircularProgress";
import { useState } from "react";
import { useEffect } from "react";
import { PreviewAudioOptions } from "../PreviewAudioOptions/PreviewAudioOptions";
import axiosInstance from "../../Axios";
import { AudioOptions } from "../../PPTPage/AudioOptions/AudioOptions";
import Cookies from "js-cookie";
import { UploadVoiceModal } from "../../UploadVoiceModal/UploadVoiceModal";
export const PPTSlideNoteTextEditor = ({
  ppt_id,
  PPTData,
  PPTSlides,
  selectedSlide,
  setSelectedSlide,
  setUpdate,
  setSlide_id,
}) => {
  const user_type_id = parseInt(Cookies.get(["user_type_id"]));
  const [notes, setNotes] = useState("");
  const [loadingNotes, setLoadingNotes] = useState(false);
  const [loadingAudio, setLoadingAudio] = useState(false);

  const [currentSpeaker, setCurrentSpeaker] = useState(PPTData.speaker);

  const textAreaHandler = (e) => {
    setNotes(e.target.value);
  };

  const handlePrevArrowClick = () => {
    const index = PPTSlides.findIndex(
      (slide) => slide.slide_id === selectedSlide.slide_id
    );
    if (index === 0) {
      setSelectedSlide(PPTSlides[PPTSlides.length - 1]);
    } else {
      setSelectedSlide(PPTSlides[index - 1]);
    }
  };

  const handleNextArrowClick = () => {
    const index = PPTSlides.findIndex(
      (slide) => slide.slide_id === selectedSlide.slide_id
    );
    if (index === PPTSlides.length - 1) {
      setSelectedSlide(PPTSlides[0]);
    } else {
      setSelectedSlide(PPTSlides[index + 1]);
    }
  };

  const handleUpdateNote = async () => {
    try {
      setLoadingNotes(true);
      const res = await axiosInstance.post(
        "/api/ppt/update_notes",
        { ppt_id: ppt_id, slide_id: selectedSlide.slide_id, notes: notes },
        { headers: { "Content-Type": "application/json" } }
      );
      setSlide_id(selectedSlide.slide_id);
      setUpdate((prev) => !prev);
    } catch (err) {
      alert(err.message);
    } finally {
      setLoadingNotes(false);
    }
  };

  const handleGenerateAudio = async () => {
    try {
      setLoadingAudio(true);
      console.log(selectedSlide);
      const res = await axiosInstance.post(
        "/api/tts",
        {
          text: notes,
          ppt_id: ppt_id,
          slide_id: selectedSlide.slide_id,
          stability: null,
          lang_code: PPTData.ppt_lang,
          voice: currentSpeaker,
          engine: PPTData.engine,
        },
        { headers: { "Content-Type": "application/json" } }
      );
      setSlide_id(selectedSlide.slide_id);
      setUpdate((prev) => !prev);
    } catch (err) {
      alert(err.message);
    } finally {
      setLoadingAudio(false);
    }
  };

  useEffect(() => {
    setNotes(selectedSlide.slide_notes);
  }, [selectedSlide]);

  return (
    <>
      <div className="slide-note-text-editor-container">
        <textarea
          id="slideNotesTextArea"
          rows="7"
          cols="92"
          className="note-textarea"
          placeholder="Slide Notes..."
          value={notes || ""}
          onChange={textAreaHandler}
        ></textarea>
      </div>
      <div className="ppt-slide-update-options-container">
        <div className="ppt-slide-update-options-button">
          {user_type_id === 1 || user_type_id === 2 ? (
            <>
              <div style={{ display: "flex", gap: "10px" }}>
                <button
                  className="note-update-btn-slide-update"
                  disabled={loadingNotes}
                  style={{
                    cursor: loadingNotes ? "not-allowed" : "pointer",
                    backgroundColor: loadingNotes ? "disabled" : "#1167E5",
                  }}
                  onClick={handleUpdateNote}
                >
                  {loadingNotes ? (
                    <>
                      <CircularProgress
                        color="inherit"
                        sx={{ marginRight: "10px" }}
                      />
                      <p className="note-btn-text-slide-update">Updating...</p>
                    </>
                  ) : (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 21"
                        fill="none"
                      >
                        <mask
                          id="mask0_156_401"
                          style={{ maskType: "alpha" }}
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="20"
                          height="21"
                        >
                          <rect
                            y="0.224609"
                            width="20"
                            height="20"
                            fill="#D9D9D9"
                          />
                        </mask>
                        <g mask="url(#mask0_156_401)">
                          <path
                            d="M16.0833 7.66146L12.5417 4.16146L13.7083 2.99479C14.0278 2.67535 14.4201 2.51562 14.8854 2.51562C15.3507 2.51562 15.7431 2.67535 16.0625 2.99479L17.2292 4.16146C17.5486 4.4809 17.7153 4.86632 17.7292 5.31771C17.7431 5.7691 17.5903 6.15451 17.2708 6.47396L16.0833 7.66146ZM14.875 8.89062L6.04167 17.724H2.5V14.1823L11.3333 5.34896L14.875 8.89062Z"
                            fill="white"
                          />
                        </g>
                      </svg>

                      <p className="note-btn-text-slide-update">Update Note</p>
                    </>
                  )}
                </button>

                <button
                  className="play-btn-slide-update"
                  onClick={handleGenerateAudio}
                  disabled={loadingAudio}
                  style={{
                    cursor: loadingAudio ? "not-allowed" : "pointer",
                    backgroundColor: loadingAudio ? "transparent" : "",
                  }}
                >
                  {loadingAudio ? (
                    <>
                      <CircularProgress
                        color="primary"
                        sx={{ marginRight: "10px" }}
                      />
                      <p className="play-btn-text-slide-update">
                        Generating...
                      </p>
                    </>
                  ) : (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M0 1.87762C0 0.451621 1.529 -0.452379 2.779 0.234621L14.319 6.58262C15.614 7.29462 15.614 9.15562 14.319 9.86762L2.78 16.2156C1.53 16.9026 0.000999928 15.9986 0.000999928 14.5726L0 1.87762Z"
                          fill="#1167E5"
                        />
                      </svg>

                      <p className="play-btn-text-slide-update">
                        Generate Audio
                      </p>
                    </>
                  )}
                </button>

                <PreviewAudioOptions
                  setUpdate={setUpdate}
                  setSlide_id={setSlide_id}
                  ppt_id={ppt_id}
                  selectedSlide={selectedSlide}
                  PPTData={PPTData}
                  setCurrentSpeaker={setCurrentSpeaker}
                  setSelectedSlide={setSelectedSlide}
                />
                <UploadVoiceModal
                  ppt_id={ppt_id}
                  slide_id={selectedSlide.slide_id}
                  setUpdate={setUpdate}
                  setSlide_id={setSlide_id}
                  setSelectedSlide={setSelectedSlide}
                />
              </div>
            </>
          ) : (
            <div></div>
          )}

          <div className="arrow-icon">
            <div className="slide-counter-container">
              <p className="current-count">
                {PPTSlides.findIndex(
                  (slide) => slide.slide_id === selectedSlide.slide_id
                ) + 1}
              </p>
              <p className="total-count">/</p>
              <p className="total-count">{PPTSlides.length}</p>
            </div>

            <svg
              onClick={handlePrevArrowClick}
              style={{ cursor: "pointer" }}
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="12"
              viewBox="0 0 16 12"
              fill="#7A7A7A"
            >
              <path
                d="M6.7 0.724218C6.9 0.924217 6.99583 1.16588 6.9875 1.44922C6.97917 1.73255 6.875 1.97422 6.675 2.17422L3.85 4.99922L15 4.99922C15.2833 4.99922 15.5208 5.09505 15.7125 5.28672C15.9042 5.47839 16 5.71589 16 5.99922C16 6.28255 15.9042 6.52005 15.7125 6.71172C15.5208 6.90339 15.2833 6.99922 15 6.99922L3.85 6.99922L6.7 9.84922C6.9 10.0492 7 10.2867 7 10.5617C7 10.8367 6.9 11.0742 6.7 11.2742C6.5 11.4742 6.2625 11.5742 5.9875 11.5742C5.7125 11.5742 5.475 11.4742 5.275 11.2742L0.7 6.69922C0.6 6.59922 0.529168 6.49088 0.487501 6.37422C0.445834 6.25755 0.425001 6.13255 0.425001 5.99922C0.425001 5.86588 0.445834 5.74088 0.487501 5.62422C0.529168 5.50755 0.6 5.39922 0.7 5.29922L5.3 0.699218C5.48333 0.515884 5.7125 0.424217 5.9875 0.424217C6.2625 0.424217 6.5 0.524218 6.7 0.724218Z"
                fill="#7A7A7A"
              />
            </svg>

            <svg
              onClick={handleNextArrowClick}
              style={{ cursor: "pointer" }}
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <mask
                id="mask0_156_408"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
              >
                <rect width="24" height="24" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_156_408)">
                <path
                  d="M13.3 17.2758C13.1 17.0758 13.0042 16.8341 13.0125 16.5508C13.0208 16.2674 13.125 16.0258 13.325 15.8258L16.15 13.0008H5C4.71667 13.0008 4.47917 12.9049 4.2875 12.7133C4.09583 12.5216 4 12.2841 4 12.0008C4 11.7174 4.09583 11.4799 4.2875 11.2883C4.47917 11.0966 4.71667 11.0008 5 11.0008H16.15L13.3 8.15078C13.1 7.95078 13 7.71328 13 7.43828C13 7.16328 13.1 6.92578 13.3 6.72578C13.5 6.52578 13.7375 6.42578 14.0125 6.42578C14.2875 6.42578 14.525 6.52578 14.725 6.72578L19.3 11.3008C19.4 11.4008 19.4708 11.5091 19.5125 11.6258C19.5542 11.7424 19.575 11.8674 19.575 12.0008C19.575 12.1341 19.5542 12.2591 19.5125 12.3758C19.4708 12.4924 19.4 12.6008 19.3 12.7008L14.7 17.3008C14.5167 17.4841 14.2875 17.5758 14.0125 17.5758C13.7375 17.5758 13.5 17.4758 13.3 17.2758Z"
                  fill="#1167E5"
                />
              </g>
            </svg>
          </div>
        </div>
      </div>
    </>
  );
};
