import React from "react";
import { useState } from "react";
import { TextareaAutosize } from "@mui/base";
import Avatar from "@mui/material/Avatar";
import "./PPTPreviewCommentSection.css";
import { Typography } from "@mui/material";
import { deepOrange, deepPurple } from "@mui/material/colors";
import MenuItem from "@mui/material/MenuItem";
import { Menu } from "@mui/material";
import Button from "@mui/material/Button";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import axiosInstance from "../../Axios";
import { useEffect } from "react";
import Cookies from "js-cookie";

export const PPTPreviewCommentSection = ({
  ppt_id,
  PPTData,
  selectedSlide,
  reviewID,
}) => {
  const [user_id, setUser_id] = useState(Cookies.get("user_id"));
  const [reference, setReference] = useState(PPTData.reference);
  const [referenceComment, setReferenceComment] = useState(
    PPTData.reference_comment
  );
  const [slideComments, setSlideComments] = useState([]);
  const [comment_id, setComment_id] = useState(null);
  const [commentReload, setCommentReload] = useState(false);

  const [comment, setComment] = useState("");

  // comment options

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClickOptions = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseOptions = () => {
    setAnchorEl(null);
  };

  const getComments = async () => {
    try {
      const res = await axiosInstance.get(
        `/api/ppt/get_ppt_slide_comments?ppt_id=${ppt_id}&slide_id=${selectedSlide.slide_id}`
      );
      setSlideComments(res.data.data);
    } catch (err) {
      alert(err.message);
    }
  };

  const addCommentHandler = async () => {
    try {
      const res = await axiosInstance.post(
        "/api/ppt/add_comment",
        {
          review_id: reviewID,
          slide_id: selectedSlide.slide_id,
          comment: comment,
        },
        { headers: { "Content-Type": "application/json" } }
      );
      setComment("");
      setCommentReload(!commentReload);
    } catch (err) {
      alert(err.message);
    }
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const deleteComment = async (comment_id) => {
    try {
      const res = await axiosInstance.post(
        `/api/ppt/delete_comment?review_id=${reviewID}&slide_id=${selectedSlide.slide_id}`,
        { comment_id: comment_id },
        { headers: { "Content-Type": "application/json" } }
      );
      setCommentReload(!commentReload);
    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    getComments();
  }, [selectedSlide, commentReload]);

  return (
    <div className="ppt-preview-comment-section-container">
      <div className="ppt-preview-ref-container">
        <div className="user-comment">
          <div
            className="refernce-header"
            style={{ display: "flex", gap: "5px", alignItems: "center" }}
          >
            <Typography variant="h6">Reference: </Typography>
            <a href={reference} target="_blank" rel="noreferrer">
              {reference}
            </a>
          </div>
        </div>

        <div className="user-comment">
          <div
            className="reference-comment-header"
            style={{ display: "flex", gap: "5px", alignItems: "center" }}
          >
            <Typography variant="h6">Reference Comments:</Typography>
            <Typography variant="body1">{referenceComment}</Typography>
          </div>
        </div>
      </div>
      {reviewID == null ? null : (
        <>
          <div className="add-comment-ppt-preview">
            <Avatar src="/broken-image.jpg" />

            <TextareaAutosize
              onChange={handleCommentChange}
              className="comment-textarea"
              placeholder="Add Comment..."
              value={comment}
              cols={88}
              maxRows={10}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "10px",
            }}
            className="btn-container"
          >
            <button className="add-comment-btn" onClick={addCommentHandler}>
              Comment
            </button>
          </div>
        </>
      )}

      <div className="comment-section-header">
        <Typography variant="h6">Slide Comments</Typography>
      </div>

      <div className="comment-section-body">
        {slideComments.length > 0 ? (
          slideComments.map((comment, index) => (
            <div key={index} className="user-comment">
              <div className="comment-total">
                <Avatar sx={{ bgcolor: deepOrange[500] }}>
                  {comment.username.slice(0, 1).toUpperCase()}
                </Avatar>
                <div className="comment-right">
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    @{comment.username}
                  </Typography>
                  <Typography variant="body1">{comment.comments}</Typography>
                </div>
              </div>

              {user_id == comment.user_id ? (
                <>
                  <Button
                    id="demo-positioned-button"
                    aria-controls={open ? "demo-positioned-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={(e) => {
                      setComment_id(comment.comment_id);
                      handleClickOptions(e);
                    }}
                    className="comment-options"
                  >
                    <MoreVertIcon />
                  </Button>

                  <Menu
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleCloseOptions}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        deleteComment(comment_id);
                        handleCloseOptions();
                      }}
                    >
                      Delete
                    </MenuItem>
                  </Menu>
                </>
              ) : null}
            </div>
          ))
        ) : (
          <Typography variant="body1">No Comments</Typography>
        )}
      </div>
    </div>
  );
};
